import React from 'react'
import RestaurantList from '../restaurants/RestaurantList'
import './App.css'

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <RestaurantList />
        v3
      </header>
    </div>
  )
}

export default App
