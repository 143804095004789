import React, { FC, Children, useCallback, useEffect, useState } from 'react'
import './RestaurantList.css'
import RestaurantSearchBar from '../restaurantsearchbar'

interface Restaurant {
  position: number
  id: string
  name: string
  address1: string
  city: string
  state: string
  zip: string
  lat: number
  long: number
  telephone: string
  tags: string
  website: string
  genre: string
  hours: string
  attire: string
  genreList: string[]
  tagList: string[]
}

// todo:dtb: remove
// const Result = styled.div`
//   margin-bottom: 20px;
//`

const pageDisplayLimit = 10

const RestaurantList: FC = () => {
  const [fullResults, setFullResults] = useState<Restaurant[]>([])
  const [filteredResults, setFilteredResults] = useState<Restaurant[]>([])
  const [currentPage, setCurrentPage] = useState<number>(4)

  useEffect(() => {
    /*
    fetch('https://code-challenge.spectrumtoolbox.com/api/restaurants', {
    headers: {
      Authorization: 'Api-Key q3MNxtfep8Gt',
    },
    });
    */
    //fetch('http://localhost:4000/restaurants')
    fetch('https://code-challenge.spectrumtoolbox.com/api/restaurants', {
      headers: {
        Authorization: 'Api-Key q3MNxtfep8Gt',
      },
    })
      .then(res => res.json())
      .then(
        result => {
          let transformedResult = result
            .map((r: Restaurant) => {
              return {
                ...r,
                name: r.name,
                genreList: r.genre.split(','),
                tagList: r.tags.split(','),
              }
            })
            .sort((a: Restaurant, b: Restaurant) => {
              const nameA = a.name.toLowerCase()
              const nameB = b.name.toLowerCase()

              if (nameA > nameB) {
                return 1
              }
              if (nameA < nameB) {
                return -1
              }
              return 0
            })

          // todo:dtb: for debug
          transformedResult = transformedResult.map(
            (r: Restaurant, index: number) => {
              return {
                ...r,
                position: index,
              }
            },
          )

          // setCurrentPage(2)
          setFullResults(transformedResult)

          const pageOffset = (currentPage - 1) * pageDisplayLimit
          setFilteredResults(
            transformedResult.slice(pageOffset, pageOffset + pageDisplayLimit),
          )
        },
        error => {
          console.error('Error retrieving restaurants.', error)
        },
      )
  }, [])

  return (
    <div>
      <div className="table">
        <div className="table-title">
          <p>Great Restaurants</p>
        </div>

        <RestaurantSearchBar />

        <div className="table-heading">
          <div className="table-cell">
            <p>Id</p>
          </div>
          <div className="table-cell">
            <p>Name</p>
          </div>
          <div className="table-cell">
            <p>City</p>
          </div>
          <div className="table-cell">
            <p>State</p>
          </div>
          <div className="table-cell">
            <p>Telephone</p>
          </div>
          <div className="table-cell">
            <p>Genres</p>
          </div>
        </div>

        {filteredResults &&
          filteredResults.map((p, index) => (
            <div className="table-row">
              <div className="table-cell">
                <p>{p.position}</p>
              </div>
              <div className="table-cell">
                <p>{p.name}</p>
              </div>
              <div className="table-cell">
                <p>{p.city}</p>
              </div>
              <div className="table-cell">
                <p>{p.state}</p>
              </div>
              <div className="table-cell">
                <p>{p.telephone}</p>
              </div>
              <div className="table-cell">
                <p>{p.genreList.join(', ')}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default RestaurantList
